/*----------------------------------------------------

1. Global Area 
2. Header Section
3. Banner Section / Slider Section
4. Feature Section
5. About Section
6. Service Section
7. Promo Section
8. Why choose Section
9. Team Section
10. Faq Section
11. Fun Fact Section
12. Pricing Plan Section
13. Testimonal Section
14. Contact Section
15. Blog Section
16. Sponsor Section
17. Project Section
18. How Work Section
17. Breadcrumb Section
18. Shop Section
19. Footer Section
20. Responsive Section

=====================================================================*/
/*=========
Font load
===========*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap");

/* @font-face {
  font-family: "Segoe Print V1";
  src: url("../fonts/c455d94eee43dc4ceeb83c0c0fd0d4c8.html");
  src: url("../fonts/c455d94eee43dc4ceeb83c0c0fd0d4c8d41d.html?#iefix") format("embedded-opentype"), url("../fonts/c455d94eee43dc4ceeb83c0c0fd0d4c8.woff2") format("woff2"), url("../fonts/c455d94eee43dc4ceeb83c0c0fd0d4c8.woff") format("woff"), url("../fonts/c455d94eee43dc4ceeb83c0c0fd0d4c8.ttf") format("truetype"), url("../fonts/c455d94eee43dc4ceeb83c0c0fd0d4c8.svg#Segoe Print V1") format("svg");
} */

/*=========
Color Code
===========*/
:root {
  --font: "DM Sans", sans-serif;
  --color-primary: #f26a82;
  --color-black: #000;
  --color-deep: #f26a82;
  --color-body: #535353;
  --color-yellow: #0abab5;
  --color-white: #fff;
}

/*global area*/
/*----------------------------------------------------*/
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 1.5;
  /* color: #555555; */
  font-family: var(--font);
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: var(--color-black);
  margin: 0;
  line-height: 1.2;
  font-family: var(--font);
} */

.bRadius-0 {
  border-radius: 0 !important;
}

.scrollup {
  width: 55px;
  right: 20px;
  z-index: 5;
  height: 55px;
  bottom: 20px;
  display: none;
  position: fixed;
  border-radius: 100%;
  line-height: 55px;
  background-color: #f26a82;
}

.scrollup i {
  color: #fff;
}

.headline h1,
.headline h2,
.headline h3,
.headline h4,
.headline h5,
.headline h6 {
  margin: 0;
  font-weight: normal;
  font-family: "Poppins";
}

.pr-text-in_item2,
.pr-text-in_item3 {
  display: block;
}

.pr-text-in_item1,
.pr-text-in_item2 {
  overflow: hidden;
}

.pr-text-in_item2,
.pr-text-in_item3 {
  -webkit-transition-duration: 1.5s;
  transition-duration: 1.5s;
  -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.pr-text-in_item2 {
  -webkit-transform: translate3d(-100.5%, 0, 0);
  transform: translate3d(-100.5%, 0, 0);
}

.pr-text-in_item3 {
  padding-bottom: 6px;
  -webkit-transform: translate3d(100.1%, 0, 0);
  transform: translate3d(100.1%, 0, 0);
}

.pr-text-in.is_shown .pr-text-in_item2,
.pr-text-in.is_shown .pr-text-in_item3 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.clinox-section-title .sub-title,
.clinox-section-title-2 .sub-title {
  font-size: 18px;
  font-weight: 500;
  color: #f26a82;
  font-family: "Segoe Print V1";
}

.clinox-section-title h2,
.clinox-section-title h1,
.clinox-section-title-2 h2,
.clinox-section-title-2 h1 {
  color: #101010;
  font-size: 42px;
  font-weight: 700;
  padding: 5px 0px;
}

.clinox-section-title h2 b,
.clinox-section-title h1 b,
.clinox-section-title-2 h2 b,
.clinox-section-title-2 h1 b {
  color: #f26a82;
  position: relative;
  font-weight: 700;
  display: inline-block;
}

.clinox-section-title h2 b:before,
.clinox-section-title h1 b:before,
.clinox-section-title-2 h2 b:before,
.clinox-section-title-2 h1 b:before {
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  bottom: -4px;
  position: absolute;
  background-color: #f26a82;
}

.clinox-section-title p,
.clinox-section-title-2 p {
  padding-top: 30px;
  line-height: 1.875;
}

.clinox-section-title-2 {
  max-width: 560px;
}

.clinox-section-title-2 .sub-title {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins";
}

.clinox-section-title-2 h2 {
  padding-bottom: 0;
}

.clinox-section-title-2 p {
  padding-top: 15px;
}

.clinox-section-title-2.text-center {
  margin: 0 auto;
}

@-webkit-keyframes btn_shine {
  100% {
    left: 200%;
  }
}

@keyframes btn_shine {
  100% {
    left: 200%;
  }
}

.clinox-blog-feed-item .inner-text .read-more a,
.clinox-blog-details-area .inner-text .read-more a,
.clinox-blog-inner-item .inner-text .more-btn a,
.clinox-blog-inner-item-2 .inner-text .more-btn a,
.clinox-pricing-item .price-btn a,
.banner-btn-wrapper .banner-btn a,
.clinox-btn a,
.clinox-btn-2 a {
  color: #101010;
  height: 55px;
  z-index: 1;
  width: 200px;
  font-weight: 600;
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  font-family: "Poppins";
  background-color: #ffcd05;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(1, 83, 159, 0.4);
  box-shadow: 0px 0px 20px 0px rgba(1, 83, 159, 0.4);
}

.clinox-blog-feed-item .inner-text .read-more a:after,
.clinox-blog-details-area .inner-text .read-more a:after,
.clinox-blog-inner-item .inner-text .more-btn a:after,
.clinox-blog-inner-item-2 .inner-text .more-btn a:after,
.clinox-pricing-item .price-btn a:after,
.banner-btn-wrapper .banner-btn a:after,
.clinox-btn a:after,
.clinox-btn-2 a:after {
  content: "";
  position: absolute;
  top: 0;
  left: -200%;
  width: 200%;
  height: 100%;
  -webkit-transform: skewX(-20deg);
  -khtml-transform: skewX(-20deg);
  transform: skewX(-20deg);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.4)),
    to(transparent)
  );
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  z-index: -1;
}

.clinox-blog-feed-item .inner-text .read-more a:hover,
.clinox-blog-details-area .inner-text .read-more a:hover,
.clinox-blog-inner-item .inner-text .more-btn a:hover,
.clinox-blog-inner-item-2 .inner-text .more-btn a:hover,
.clinox-pricing-item .price-btn a:hover,
.banner-btn-wrapper .banner-btn a:hover,
.clinox-btn a:hover,
.clinox-btn-2 a:hover {
  color: #fff;
  background-color: #f26a82;
}

.clinox-blog-feed-item .inner-text .read-more a:hover:after,
.clinox-blog-details-area .inner-text .read-more a:hover:after,
.clinox-blog-inner-item .inner-text .more-btn a:hover:after,
.clinox-blog-inner-item-2 .inner-text .more-btn a:hover:after,
.clinox-pricing-item .price-btn a:hover:after,
.banner-btn-wrapper .banner-btn a:hover:after,
.clinox-btn a:hover:after,
.clinox-btn-2 a:hover:after {
  -webkit-animation: btn_shine 1.2s ease;
  animation: btn_shine 1.2s ease;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.clinox-btn-2 a {
  color: #fff;
  border-radius: 30px;
  background-color: #f26a82;
}

.clinox-btn-2 a:hover {
  background-color: #f26a82;
}

.slider-inner-item {
  padding: 0px 15px;
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.top-position {
  top: -35px;
  margin-bottom: -35px;
}

.page-section-padding {
  padding: 120px 0px;
}

.clinox-pagination {
  margin-top: 35px;
}

.clinox-pagination li a {
  width: 45px;
  height: 45px;
  color: #101010;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0px 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 2px solid #dcdcdc;
}

.clinox-pagination li a:hover,
.clinox-pagination li a.active {
  color: #f26a82;
  border: 2px solid #f26a82;
}

.top-sticky-sidebar {
  top: 100px;
  position: sticky;
}

@-webkit-keyframes Zooming {
  0% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@keyframes Zooming {
  0% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

.clinox-error-text {
  padding-top: 40px;
  padding-left: 30px;
}

.clinox-error-text h3 {
  color: #101010;
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 25px;
}

.clinox-error-text p {
  padding-bottom: 30px;
}

.clinox-section-title-3 .subtitle {
  font-weight: 600;
  display: inline-block;
  color: #f26a82;
  padding-left: 35px;
  font-family: "Poppins";
  position: relative;
}

.clinox-section-title-3 .subtitle:before {
  top: 0;
  left: 0;
  line-height: 0;
  color: #ffcd05;
  font-size: 30px;
  content: "...";
  position: absolute;
}

.clinox-section-title-3 .subtitle:after {
  left: 0;
  bottom: 7px;
  width: 22px;
  height: 3px;
  content: "";
  position: absolute;
  border-radius: 10px;
  background-color: #ffcd05;
}

.clinox-section-title-3 h2 {
  color: #000000;
  font-size: 42px;
  font-weight: 700;
  padding-top: 20px;
  line-height: 1.238;
}

.clinox-section-title-3 p {
  padding-top: 25px;
  line-height: 1.625;
}

.clinox-section-title-3.text-center {
  margin: 0 auto;
  max-width: 610px;
}

@-webkit-keyframes btn_shine {
  100% {
    left: 200%;
  }
}

@keyframes btn_shine {
  100% {
    left: 200%;
  }
}

.clinox-btn-3 a {
  color: #fff;
  width: 200px;
  height: 60px;
  overflow: hidden;
  font-weight: 600;
  position: relative;
  border-radius: 5px;
  background-color: #f26a82;
}

/* .clinox-btn-3 a:before,
.clinox-btn-3 a:after {
  right: 20px;
  content: "";
  width: 20px;
  bottom: -5px;
  height: 20px;
  position: absolute;
  background-repeat: no-repeat;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
  background-image: url(../img/img-5/shape/btn-sh1.png);
} */

/* .clinox-btn-3 a:after {
  right: 34px;
  bottom: -7px;
  background-image: url(../img/img-5/shape/btn-sh2.png);
} */

.clinox-btn-3 a span {
  width: 100%;
  z-index: 1;
  height: 100%;
  display: block;
  line-height: 60px;
  position: relative;
  text-align: center;
}

.clinox-btn-3 a span:after {
  content: "";
  position: absolute;
  top: 0;
  left: -200%;
  width: 200%;
  height: 100%;
  -webkit-transform: skewX(-20deg);
  -khtml-transform: skewX(-20deg);
  transform: skewX(-20deg);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.4)),
    to(transparent)
  );
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  z-index: -1;
}

.clinox-btn-3 a span:hover:after {
  -webkit-animation: btn_shine 1.2s ease;
  animation: btn_shine 1.2s ease;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.clinox-btn-3 a:hover:before {
  right: 38px;
}

.clinox-btn-3 a:hover:after {
  right: 20px;
}

/*---------------------------------------------------- */
/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
.mt-none-5 {
  margin-top: -5px;
}

.mt-none-10 {
  margin-top: -10px;
}

.mt-none-15 {
  margin-top: -15px;
}

.mt-none-20 {
  margin-top: -20px;
}

.mt-none-25 {
  margin-top: -25px;
}

.mt-none-30 {
  margin-top: -30px;
}

.mt-none-35 {
  margin-top: -35px;
}

.mt-none-40 {
  margin-top: -40px;
}

.mt-none-45 {
  margin-top: -45px;
}

.mt-none-50 {
  margin-top: -50px;
}

.mt-none-55 {
  margin-top: -55px;
}

.mt-none-60 {
  margin-top: -60px;
}

.mt-none-65 {
  margin-top: -65px;
}

.mt-none-70 {
  margin-top: -70px;
}

.mt-none-75 {
  margin-top: -75px;
}

.mt-none-80 {
  margin-top: -80px;
}

.mt-none-85 {
  margin-top: -85px;
}

.mt-none-90 {
  margin-top: -90px;
}

.mt-none-95 {
  margin-top: -95px;
}

.mt-none-100 {
  margin-top: -100px;
}

/*-- Margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

/*-- Margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

/*-- Margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

/*---------------------------------------------------- */
/*Header area*/
/*----------------------------------------------------*/
.clinox-header-section {
  z-index: 10;
  position: relative;
}

.clinox-header-section .sticky-brand-logo {
  display: none;
  width: 145px;
}

.clinox-header-section .dropdown {
  position: relative;
}

.clinox-header-section .dropdown:hover .dropdown-menu {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.clinox-header-section .dropdown:hover:after {
  color: #f26a82;
}

.clinox-header-section .dropdown:after {
  top: 2px;
  color: #e3002a;
  right: -14px;
  content: "+";
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.clinox-header-section .dropdown li:after {
  top: 8px;
  right: 15px;
}

.clinox-header-section .dropdown-menu {
  left: 0;
  top: 44px;
  z-index: 100;
  margin: 0px;
  padding: 0px;
  height: auto;
  min-width: 250px;
  display: block;
  border: none;
  border-radius: 0;
  position: absolute;
  padding: 20px 0px;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  background-color: #fff;
  background-clip: inherit;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform-origin: center top 0;
  transform-origin: center top 0;
  -webkit-box-shadow: 0 13px 18px -8px rgba(0, 0, 0, 0.15),
    0 29px 45px 4px rgba(0, 0, 0, 0.08), 0 11px 55px 10px rgba(0, 0, 0, 0.07);
  box-shadow: 0 13px 18px -8px rgba(0, 0, 0, 0.15),
    0 29px 45px 4px rgba(0, 0, 0, 0.08), 0 11px 55px 10px rgba(0, 0, 0, 0.07);
}

.clinox-header-section .dropdown-menu .dropdown-menu {
  top: 20px;
  opacity: 0;
  left: 250px;
}

.clinox-header-section .dropdown-menu .dropdown-menu a {
  font-size: 12px;
  font-weight: 600;
  color: #1a2428;
}

.clinox-header-section .dropdown-menu .dropdown-menu a:hover {
  color: #f26a82;
}

.clinox-header-section .dropdown-menu .dropdown-menu a:before {
  display: none;
}

.clinox-header-section .dropdown-menu li {
  display: block;
  margin: 0 !important;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

.clinox-header-section .dropdown-menu li:last-child {
  border-bottom: none;
}

.clinox-header-section .dropdown-menu li:after {
  display: none;
}

.clinox-header-section .dropdown-menu li:hover .dropdown-menu {
  top: 0;
  opacity: 1;
}

.clinox-header-section .dropdown-menu a {
  width: 100%;
  display: block;
  position: relative;
  padding: 10px 20px 10px !important;
  color: #1a2428 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.clinox-header-section .dropdown-menu a:before {
  display: none;
}

.clinox-header-section .dropdown-menu a:after {
  display: none;
}

.clinox-header-section .dropdown-menu a:hover {
  color: #f26a82 !important;
  margin-left: 10px;
}

.clinox-header-section .dropdown-menu a:hover:before {
  width: 10px;
}

.clinox-header-top-wrap {
  background-color: #000e58;
}

.clinox-header-top-wrap .top-info {
  color: #fff;
  font-weight: 500;
}

.clinox-header-top-wrap .top-info li {
  margin-right: 50px;
  position: relative;
}

.clinox-header-top-wrap .top-info li:before {
  top: 0;
  width: 2px;
  content: "";
  right: -28px;
  height: 20px;
  position: absolute;
  background-color: #003571;
}

.clinox-header-top-wrap .top-info li:last-child {
  margin-right: 0;
}

.clinox-header-top-wrap .top-info li:last-child:before {
  display: none;
}

.clinox-header-top-wrap .top-info i {
  color: #f26a82;
  margin-right: 10px;
}

.clinox-header-top-wrap .top-social-btn .top-social a {
  color: #fff;
  margin-left: 20px;
}

.clinox-header-top-wrap .top-social-btn .top-social a:hover {
  color: #f26a82;
}

.clinox-header-top-wrap .top-social-btn .top-btn {
  margin-left: 25px;
}

.clinox-header-top-wrap .top-social-btn .top-btn a {
  color: #fff;
  height: 50px;
  width: 130px;
  font-size: 14px;
  font-weight: 500;
  background-color: #f26a82;
}

.clinox-header-logo-cta-content {
  padding: 25px 0px;
}

.clinox-header-logo-cta-content .brand-logo {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.clinox-header-logo-cta-content .header-cta-wrapper {
  width: 60%;
}

.clinox-header-logo-cta-content .header-info-item {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.clinox-header-logo-cta-content .header-info-item:last-child {
  margin-right: 0;
  padding-left: 0;
}

.clinox-header-logo-cta-content .header-info-item label {
  color: #101010;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}

.clinox-header-logo-cta-content .header-info-item .hd-item-icon {
  margin-right: 10px;
}

.clinox-header-logo-cta-content .header-info-item .hd-item-icon i {
  line-height: 1;
  font-size: 35px;
  color: #f26a82;
}

.clinox-header-logo-cta-content .header-info-item .hd-item-meta span {
  color: #101010;
  display: block;
  font-size: 14px;
  font-weight: 400;
}

.header-navigation-content {
  padding: 20px 30px;
  border-radius: 5px;
  background-color: #000e58;
}

.header-navigation-content .navbar-nav {
  display: inherit;
}

.header-navigation-content .main-navigation li {
  margin-right: 60px;
}

.header-navigation-content .main-navigation li a {
  color: #fff;
  font-weight: 500;
  padding-bottom: 20px;
}

.header-navigation-content .main-navigation li:hover a {
  color: #f26a82;
}

.header-cart-btn-search button {
  padding: 0;
  color: #fff;
  border: none;
  margin-left: 40px;
  position: relative;
  background-color: transparent;
}

.header-cart-btn-search .h-cart-btn button {
  position: relative;
}

.header-cart-btn-search .h-cart-btn button:before {
  top: 2px;
  left: -20px;
  width: 1px;
  content: "";
  height: 20px;
  position: absolute;
  background-color: #fff;
}

.header-style-two {
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  padding-top: 40px;
}

.clinox-header-content-2 {
  padding: 0px 115px;
}

.clinox-header-content-2 .dropdown:hover:after {
  color: #fff;
}

.clinox-header-content-2 .navbar-nav {
  display: inherit;
}

.clinox-header-content-2 .main-navigation li {
  margin-left: 60px;
}

.clinox-header-content-2 .main-navigation li a {
  color: #fff;
  font-weight: 500;
  padding-bottom: 20px;
}

.clinox-header-content-2 .header-cta-btn {
  margin-left: 70px;
}

.clinox-header-content-2 .header-cta-btn .cta-number a {
  font-size: 18px;
  font-weight: 600;
  color: #f26a82;
}

.clinox-header-content-2 .header-cta-btn .cta-btn {
  margin-left: 50px;
}

.clinox-header-content-2 .header-cta-btn .cta-btn a {
  color: #fff;
  width: 200px;
  height: 60px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #f26a82;
}

.clinox-header-content-2 .header-cta-btn .cta-btn a:hover {
  color: #f26a82;
  background-color: #fff;
}

.clinox-header-section.sticky-on {
  top: 0;
  width: 100%;
  z-index: 20;
  position: fixed;
  padding: 10px 0px;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  background-color: #000e58;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  -webkit-box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
}

.clinox-header-section.sticky-on .dropdown-menu {
  top: 48px;
}

.clinox-header-section.sticky-on .clinox-header-top-wrap,
.clinox-header-section.sticky-on .clinox-header-logo-cta {
  display: none;
}

.clinox-header-section.sticky-on .sticky-brand-logo {
  display: block;
}

.clinox-header-section.sticky-on .header-navigation-content {
  padding: 5px 0;
  background-color: #000e58;
}

.clinox-header-section.sticky-on.header-style-two {
  padding: 10px 0px;
  background-color: #000e58;
}

.clinox-header-section.sticky-on.header-style-two .dropdown-menu {
  top: 51px;
}

.mxw_1350 {
  max-width: 1275px;
}
.mxw_1350 ul {
  list-style: none;
}
.mxw_1350 .row {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.col-lg-3 {
  width: 20%;
}
.about {
  width: 25%;
}
@media (max-width: 1199px) {
  .mxw_1350 .row {
    flex-wrap: wrap;
    /* flex-direction: column; */
  }
  .col-lg-3 {
    width: 40%;
  }
  .about {
    width: 40%;
  }
}
@media (max-width: 840px) {
  .mxw_1350 .row {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .col-lg-3 {
    width: 80%;
  }
  .about {
    width: 80%;
  }
}
.body_wrap {
  overflow: hidden;
  position: relative;
}

.thm-btn {
  font-size: 16px;
  padding: 20px 38px;
  z-index: 1;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  background: var(--color-primary);
  line-height: 1.3;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
  position: relative;
  font-weight: 500;
  text-transform: capitalize;
}

@media (max-width: 1199px) {
  .thm-btn {
    padding: 17px 28px;
  }
}

.thm-btn:hover .btn-wrap span:nth-child(2) {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.thm-btn .btn-wrap {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
}
.thm-btn .btn-wrap > span {
  margin: 0;
}

.thm-btn .btn-wrap span:nth-child(1) {
  display: block;
  position: relative;
}

.thm-btn .btn-wrap span:nth-child(2) {
  position: absolute;
  top: 100%;
  display: block;
}

.thm-btn--transparent {
  background-color: transparent;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border: 1px solid var(--color-black);
}

.thm-btn--transparent:hover {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}
.button {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}
.thm-btn:hover .btn-wrap span:nth-child(1) {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
  color: #fff;
}
.thm-btn .btn-wrap span {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.4s ease;
}
.thm-btn--transparent:hover .btn-wrap span {
  color: white;
}
.thm-btn--transparent .btn-wrap span {
  color: var(--color-black);
}

.thm-btn__icon {
  padding-right: 56px;
}

.thm-btn__icon i {
  position: absolute;
  top: 54%;
  right: 32px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  font-size: 16px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.thm-btn__icon:hover i {
  right: 30px;
}

.thm-btn.br-0 {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.btns a {
  margin-right: 30px;
  margin-top: 20px;
}

.btns a:last-child {
  margin-right: 0;
}

@media (max-width: 991px) {
  .btns a {
    margin-right: 20px;
  }
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

.header {
  font-family: var(--font);
  z-index: 9;
  position: relative;
  background-color: var(--color-white);
}

.header .container {
  width: 100%;
}

.header__top-wrap {
  border-bottom: 1px solid #ebebeb;
  padding: 9px 0;
}

@media (max-width: 991px) {
  .header__top-wrap {
    display: none;
  }
}

@media (max-width: 991px) {
  .header__top {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .header__top {
    display: none;
  }
}

.header__language ul {
  list-style: none;
}

.header__language ul > li {
  position: relative;
}

.header__language ul .lang-btn {
  display: block;
  text-align: right;
  font-size: 16px;
}

.header__language ul .lang-btn img {
  margin-right: 4px;
}

.header__language ul .lang-btn i {
  font-size: 11px;
  padding-left: 2px;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.header__language ul li:hover ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.header__language ul li a {
  color: inherit;
}

.header__language .lang_sub_list {
  background: var(--color-white);
  border-radius: 5px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  top: 120%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: absolute;
  right: 0;
  z-index: 9;
  -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  padding-left: 0;
}

.header__language .lang_sub_list ul > li {
  border-radius: 4px;
  overflow: hidden;
}

.header__language .lang_sub_list li a {
  display: block;
  padding-right: 29px;
  min-height: 37px;
  line-height: 37px;
  padding-left: 18px;
  font-size: 15px;
  font-weight: 500;
}

.header__language .lang_sub_list li a:hover {
  background: #f6f6f6;
}

.header__language .lang_sub_list li:not(:last-child) {
  border-bottom: 1px solid #ededed;
}

/*  */
.header__main {
  display: flex;
  /* width: 100%; */
  /* flex-direction: row; */
  /* border: 5px solid black; */
  flex-wrap: nowrap;
}
/* .header_left {
  width: 20%;
}*/
/* .header__logo img {
  width: 200px;
} */
/*  */
.header__main-right {
  width: 30%;
  display: flex;
  justify-content: space-between;
  /* margin-left: 120px; */
  /* font-weight: 600; */
  margin-left: 0px;
  width: fit-content;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header__main-right {
    /* margin-left: 30px; */
  }
}

@media (max-width: 1199px) {
  .header__main-right {
    /* margin-left: 30px; */
  }
}

@media (max-width: 1199px) {
  .header__cta {
    display: none;
  }
}

@media (max-width: 1199px) {
  .header__btn {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .header__btn {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .header__logo {
    /* margin-left: 20px; */
  }
}

@media (max-width: 767px) {
  .header__logo {
    position: absolute;
    right: 15px;
  }
}

.header__info > li {
  font-size: 15px;
  color: var(--color-black);
  position: relative;
}

.header__info > li:not(:last-child) {
  margin-right: 20px;
  padding-right: 20px;
}

.header__info > li:not(:last-child)::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 1px;
  height: 15px;
  background-color: #d9d9d9;
  content: "";
}

.header__info > li i {
  color: var(--color-yellow);
  font-size: 14px;
  margin-right: 8px;
}

.header__social a {
  color: #000;
  font-size: 15px;
}

.header__social a:not(:last-child) {
  margin-right: 20px;
}

.header__social a:hover {
  color: var(--color-yellow);
}

.header__main-wrap {
  /* position: relative; */
  position: fixed;
  top: 0;
  z-index: 1;
  padding: 17px 0;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: row; */
}
/* .header__main-wrap img {
  width: 100px;
} */
.header__main-left {
  width: 16%;
}
@media (max-width: 767px) {
  .header__main-wrap {
    padding: 25px 0;
  }
}

.header__main-wrap.uk-active {
  background-color: var(--color-white);
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  z-index: 99;
}

.header__cta .icon {
  width: 28px;
  margin-right: 17px;
}

.header__cta .content {
  color: var(--color-black);
}

.header__cta .content span {
  display: block;
  margin: 0;
}

.header__cta .content a {
  color: var(--color-black);
}

@media (min-width: 1292px) {
  .hamburger_menu {
    display: none;
  }
}

.main-menu {
  /* margin-left: auto; */
  /* width: 54%; */
}
.main-menu__nav {
  /* margin: 0px 30px 0px 20px; */
}

.main-menu__nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-menu__nav ul li {
  position: relative;
}

.main-menu__nav ul li:not(:last-child) {
  margin-right: 45px;
}

@media (max-width: 1199px) {
  .main-menu__nav ul li:not(:last-child) {
    margin-right: 33px;
  }
}

.main-menu__nav ul li .submenu li {
  margin-right: 0 !important;
}

.main-menu__nav ul li a {
  display: block;
  text-decoration: none;
  font-size: 16px;
  /* font-weight: 600; */
  /* font-weight: 500; */
  margin-right: 5px;
  color: var(--color-black);
  position: relative;
  padding: 10px 0;
}

.menu-item-has-children {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-menu__nav ul li.menu-item-has-children > a::after {
  position: absolute;
  top: 50%;
  /* content: "\f078"; */
  content: "";
  font-size: 11px;
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  padding-left: 6px;
  font-weight: 400;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main-menu__nav ul li:hover > a,
.main-menu__nav ul li.active > a {
  color: var(--color-primary);
}

.main-menu__nav ul li:hover > .submenu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(100%);
  transform: scaleY(100%);
}

.main-menu__nav ul li .submenu {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  /* min-width: 215px; */
  min-width: 235px;
  top: 115%;
  visibility: hidden;
  opacity: 0;
  background: var(--color-white);
  padding: 11px 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  z-index: 3;
  text-align: left;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  border-radius: 5px;
}

.main-menu__nav ul li .submenu li {
  padding: 0;
}

.main-menu__nav ul li .submenu li a {
  padding: 6px 25px;
}

.main-menu__nav ul li .submenu li:hover > a,
.main-menu__nav ul li .submenu li.active > a {
  color: var(--color-primary);
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

/* .main-menu__nav ul li .submenu li.menu-item-has-children > a::after {
  position: absolute;
  top: 11px;
  right: 16px;
  line-height: 1;
  font-size: 13px;
  content: "\f105";
  letter-spacing: 1px;
} */

.main-menu__nav ul li .submenu ul {
  left: 100%;
  top: 0px;
}

.main-menu ul li.menu-last ul.submenu {
  right: 0;
  left: auto;
}

.main-menu ul li.menu-last ul.submenu ul {
  right: auto;
  left: -100%;
}

.border_effect a {
  display: inline !important;
  width: 100%;
  background-repeat: no-repeat;
  background-position-y: -2px;
  background-image: linear-gradient(
    transparent calc(100% - 2px),
    currentColor 1px
  );
  -webkit-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.border_effect a:hover {
  background-size: 100% 100%;
  color: inherit;
}

.footer {
  background-color: var(--color-primary);
  background-image: url(../images/footernoise.png);
  margin-top: 2rem;
}

.footer__widget h3 {
  color: white;
  font-size: 22px;
  margin-bottom: 30px;
}

.footer__widget p {
  color: #ffffff;
  font-size: 16px;
  text-align: left;
  line-height: 30px;
}

.footer__widget .f-text {
  font-size: 14px;
  color: var(--color-white);
  position: relative;
  z-index: 1;
}

.footer__widget .f-text span {
  position: relative;
}

.footer__widget .f-text span span {
  color: var(--color-yellow);
}

.footer__widget .f-text .f-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.footer__links li:not(:last-child) {
  margin-bottom: 20px;
}

.footer__links li a {
  color: #ffffff;
  font-size: 16px;
}

.footer__links li a:hover {
  color: var(--color-white);
}

.footer__col:nth-child(2) {
  padding-left: 70px;
}

@media (max-width: 991px) {
  .footer__col:nth-child(2) {
    padding-left: 15px;
  }
}

.footer__col:nth-child(3) {
  padding-left: 70px;
}

@media (max-width: 991px) {
  .footer__col:nth-child(3) {
    padding-left: 15px;
  }
}

.footer__info li {
  color: #ffffff;
}

.footer__info li:not(:last-child) {
  margin-bottom: 20px;
}

.footer__info li i {
  margin-right: 15px;
  color: #ffffff;
  font-size: 14px;
}

.footer__subscribe-wrap {
  border-bottom: 1px solid rgba(222, 226, 238, 0.08);
  margin-top: -20px;
}

.footer__subscribe-text {
  margin-top: 20px;
}

.footer__subscribe-text h2 {
  font-size: 32px;
  font-weight: 700;
  color: var(--color-white);
  margin-bottom: 12px;
}

.footer__subscribe-text h2 span {
  color: var(--color-yellow);
}

.footer__subscribe-text p {
  color: var(--color-white);
}

.footer__subscribe {
  max-width: 514px;
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.footer__subscribe input {
  background: rgba(255, 255, 255, 0.1);
  color: var(--color-white);
  height: 63px;
  width: 100%;
  border: 0;
  padding: 0 20px;
}

.footer__subscribe button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color-yellow);
  border: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  height: 100%;
  font-size: 15px;
}

@media (max-width: 767px) {
  .footer__subscribe button {
    position: relative;
    width: 100%;
  }
}

.footer__subscribe button .btn-wrap span:nth-child(2) {
  left: 0;
  right: 0;
}
.footer__instagram {
  display: flex;
}
.footer__instagram .thumb {
  width: 115px;
  height: 115px;
  overflow: hidden;
  position: relative;
}

.footer__instagram .thumb::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.54);
  content: "";
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
}

.footer__instagram .thumb::after {
  position: absolute;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "\f16d";
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
  color: var(--color-white);
  opacity: 0;
  font-size: 18px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.footer__instagram .thumb img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.footer__instagram .thumb:not(:last-child) {
  margin-right: 20px;
}

.footer__instagram .thumb:hover::before,
.footer__instagram .thumb:hover::after {
  opacity: 1;
}

.footer__instagram .thumb:hover::after {
  top: 50%;
}

.footer__awards {
  font-size: 16px;
  color: #ffffff;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
  line-height: 1;
}

.footer__awards span {
  color: var(--color-white);
  font-size: 55px;
  font-weight: 600;
  margin-left: 50px;
}

.footer__bottom {
  border-top: 1px solid rgba(222, 226, 238, 0.08);
}
#footer {
  padding: 2em;
}
.footer__copyright {
  color: #ffffff;
}

.footer__copyright a {
  color: #000000;
}
#footer-a {
  color: #000000;
}
.footer__nav li:not(:last-child) {
  margin-right: 35px;
}

.footer__nav li a {
  font-size: 15px;
  color: #ffffff;
}

.footer__nav li a:hover {
  color: var(--color-white);
}

.footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #1e4a57;
  padding: 0 125px;
  /* -ms-flex-wrap: wrap; */
  /* flex-wrap: wrap; */
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  /* .footer__social {
    padding: 0 50px;
  } */
}

@media (max-width: 1199px) {
  .footer__social {
    padding: 0;
  }
}

@media (min-width: 840px) {
  .footer__social li:not(:last-child) {
    border-right: 1px solid #1e4a57;
  }
}
@media (max-width: 840px) {
  .footer__social {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-wrap: wrap;
    /* padding: 0 105px; */
  }
  .footer__social > * {
    flex-basis: 45%;
  }
}

.footer__social li {
  width: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 33px 10px;
}

@media (max-width: 840px) {
  .footer__social li {
    width: 100%;
    border-top: 1px solid #1e4a57;
  }

  .footer__social li:nth-child(2),
  .footer__social li:nth-child(4) {
    border-top: 1px solid #1e4a57;
    border-left: 1px solid #1e4a57;
  }
  .footer__social li:nth-child(3),
  .footer__social li:nth-child(4) {
    border-bottom: 1px solid #1e4a57;
  }
  .footer__social li:nth-child(5) {
    border-right: none;
    border-left: none;
    border-top: none;
  }
}
@media (max-width: 500px) {
  .footer__social {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-wrap: wrap;
    /* flex-direction: column; */
  }
  .footer__social li:nth-child(2),
  .footer__social li:nth-child(4) {
    border-top: 1px solid #1e4a57;
    border-left: none;
  }
  .footer__social li:nth-child(4) {
    border-top: none;
  }
  /* .footer__social > * {
    flex-basis: 40%;
  } */
}
/* .footer__social li:not(:last-child) {
  border-right: 1px solid #1e4a57;
  width: 100%;
} */
.footer__social li {
  width: 100%;
}
/* .footer__social li/ */
/* .footer__social li:not(:last-child) {
  border-right: 1px solid #1e4a57;
  width: 100%;
} */

.footer__social li a {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 991px) {
  .footer__social li a {
    font-size: 16px;
  }
}

.footer__social li a i {
  font-size: 18px;
  margin-right: 24px;
  color: var(--color-white);
}

@media (max-width: 991px) {
  .footer__social li a i {
    margin-right: 15px;
  }
}
/*  */
.navmenu {
  overflow-y: scroll;
  background-color: whitesmoke;
  width: 85%;
  height: 100vh;
  /* display: flex;
  justify-content: center; */
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
  z-index: 999;
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
}

.navmenu.active {
  /* left: 0; */
  right: 0;
  transition: 350ms;
}
/* .navMenuItems {
  width: 100%;
  padding-left: 0px;
  list-style: none;
  margin: 0px;
  overflow-y: scroll;
} */
.navText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px 8px 16px;
  /* list-style: none; */
  height: 60px;
  font-family: "Lato", sans-serif;
}

.navText a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.navText:hover a {
  color: #f26a82;
}
.navMenuItems {
  width: 100%;
  padding-left: 0px;
  margin: 0px;
  overflow-y: scroll;
}
/* .navMenuItems {
  padding-inline-start: 0px;
} */

.navbarToggle {
  background-color: whitesmoke;
  width: 100%;
  height: 80px;
  display: flex;
  /* justify-content: start; */
  justify-content: flex-start;
  align-items: center;
}
span {
  margin-left: 10px;
}
/* .navMenuItems li:not(:first-child) {
  padding: 20px 0px;
} */
/* Navbar */
header {
  /* color: black;
  height: 7rem;
  padding: 0 2rem; */
  /* background-color: whitesmoke; */
  /* position: relative; */
}
/* .logo {
  width: 10%;
} */
.logo img {
  height: 7rem;
}
.topmenu {
  /* background-image: url(../images/backgrounds/navbar3.png); */
  background-size: contain;
  width: 100%;
  height: 7rem;
  padding: 0 2rem;
  /* background-color: whitesmoke; */
  /* background-color: transparent; */
  display: flex;
  align-items: center;
  /* position: fixed; */
}
.topmenu.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* background-color: transparent; */
  /* background-color: whitesmoke; */
  z-index: 999;
}
.menubars {
  display: initial;
  background: black;
  color: white;
  padding: 8px 7px 0px 8px;
}
.listitem {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 20px;
}
.listitem span {
  text-transform: uppercase;
}
.listitem a {
  color: black;
  text-decoration: none;
}
.listitem:hover {
  color: #0abab5;
}
.listitem:hover a {
  color: #0abab5;
}
.color {
  color: #0abab5;
}
.colorBlack {
  color: black;
}
.droplistitem {
  display: flex;
  margin: 0px 30px;
  /* justify-content: center; */
  align-items: center;
  padding: 7px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.droplistitem:hover {
  list-style-type: circle !important ; /* Set the list style to a filled circle on hover */
  color: #0abab5; /* Set the color to green on hover */
}
.droplistitem:hover a {
  color: #0abab5 !important;
}

.droplistitem:hover i {
  color: #0abab5 !important;
}
.text .navitem {
  text-decoration: none;
  margin: 0 2rem;
  font-size: 20px;
  color: #0abab5;
  padding-bottom: 10px;
}

.text .navitem:hover {
  /* color: #0abab5; */
  color: #f26a82;
}
.text {
  margin-right: 2.5rem;
}
.dropdownItem {
  display: block;
  padding: 3px 10px;
  text-decoration: none;
  font-size: 15px;
  color: #0abab5;
  background-color: whitesmoke;
  border-bottom: 2px solid rgba(128, 128, 128, 0.472);
}

.dropdownItem:hover {
  color: #f26a82;
}

.dropdownContainer {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: whitesmoke;
  min-width: 180px;
  line-height: 1.5em;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  padding: 10px;
  /* margin-top: 10px; */
  border-top: 2px solid #f26a82;
  font-size: 18px;
}

.dropdownContainer:hover .dropdownContent,
.dropdownContainer:focus .dropdownContent {
  display: block;
}

#button {
  background-color: #f26a82;
  color: white;
  border: none;
  padding: 10px 15px;
  border: 2px solid whitesmoke;
  font-family: "Inter", sans-serif;
  border-radius: 10px;
  text-transform: capitalize;
  /* font-size: 15px; */
}

#button:hover {
  /* background-color: #f26a82; */
  background-color: whitesmoke;
  border: 2px solid #0abab5;
  color: #0abab5;
}
/*  */

.dropdownCContent {
  display: none;
  /* position: absolute; */
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* Style the links inside the dropdown */
.dropdownCContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color on hover */
.dropdownCContent a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown content when the corresponding link is clicked */
.Aactive {
  display: initial;
}

/*  */
/* @media only screen and (max-width: 1050px) {
  .menubars {
    display: initial;
  }
  .text {
    display: none;
  }
  .topmenu {
    background-size: cover;
  }
  .navmenu {
    display: none;
  }
} */

/*  */
@media (max-width: 1292px) {
  .main-menu {
    display: none;
  }
  .header__main-right.ul_li {
    display: none !important;
  }
}
@media (max-width: 800px) {
  .header__logo img {
    width: 200px !important;
  }
  .header__main-right {
    align-items: center;
  }
  /* .main-menu {
    display: none;
  }
  .header__main-right.ul_li {
    display: none !important;
  } */
}
@media (max-width: 390px) {
  .header__logo img {
    width: 150px !important;
  }
}
