.outerHeader {
  background-image: url(../images/topSectionImage23.jpg);
  background-size: cover;
  height: 90vh;
  display: flex;
  justify-content: center;
  color: whitesmoke;

  align-items: center;
  text-align: center;
  font-family: "Poppins", "sans-serif";
}
.text {
  margin-top: 90px;
  color: whitesmoke;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text h2 {
  font-family: "Poppins", "sans-serif";
  font-size: 2.25rem;
  font-weight: 700;
  margin: 15px 0px;
  color: whitesmoke;
}
.text p {
  font-size: 20px;
  text-align: center;
  width: 80%;
}
@media screen and (max-width: 1055px) {
  .outerHeader {
    /* background-image: url(../images/image242.png); */
    height: 65vh;
  }
  .text h2 {
    font-size: 2rem;
  }
  .text p {
    font-size: 18px;
    width: 100%;
  }
}
@media screen and (max-width: 723px) {
  .outerHeader {
    /* background-image: url(../images/image242.png); */
    height: 65vh;
  }
  .text h2 {
    font-size: 1.75rem;
  }
  .text p {
    font-size: 18px;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .outerHeader {
    /* background-image: url(../images/image24.png); */
    height: 65vh;
  }
  .text h2 {
    font-size: 1.5rem;
  }
  .text p {
    font-size: 16px;
  }
}

@media screen and (max-width: 445px) {
  .outerHeader {
    /* background-image: url(../images/image24.png); */
    height: 65vh;
  }
  .text h2 {
    font-size: 1.25rem;
  }
  .text p {
    font-size: 13px;
  }
}
@media screen and (max-width: 300px) {
  .outerHeader {
    /* background-image: url(../images/image24.png); */
    height: 50vh;
  }
  .text h2 {
    font-size: 1.25rem;
  }
  .text p {
    font-size: 14px;
    display: none;
  }
}
