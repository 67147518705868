@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: inherit;
  }
}
.ul-li ul {
  margin: 0;
  padding: 0;
}
.ul-li ul li {
  list-style: none;
  display: inline-block;
}

.ul-li-block ul {
  margin: 0;
  padding: 0;
}
.ul-li-block ul li {
  display: block;
  list-style: none;
}

#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: visible;
  /* background: #ffff url("../img/loader.svg") no-repeat center center; */
}

[data-background] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
}
a:hover,
a:focus {
  text-decoration: none;
}

.header__logo img {
  /* max-width: 100%; */
  /* width: 250px !important; */
  width: 250px;
  height: auto;
  margin-right: 20px;
}

button {
  cursor: pointer;
}

.form-control:focus,
button:visited,
button.active,
button:hover,
button:focus,
input:visited,
input.active,
input:hover,
input:focus,
textarea:hover,
textarea:focus,
a:hover,
a:focus,
a:visited,
a.active,
select,
select:hover,
select:focus,
select:visited {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: none;
  color: inherit;
}

.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.relative-position {
  position: relative;
}

.pera-content p {
  margin-bottom: 0;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-right {
  text-align: right;
}

.background_overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

/* order & unorder list reset - start */
.ul_li,
.ul_li_right,
.ul_li_center,
.ul_li_between {
  margin: 0px;
  padding: 0px;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
}
.ul_li > li,
.ul_li_right > li,
.ul_li_center > li,
.ul_li_between > li {
  float: left;
  list-style: none;
  display: inline-block;
}
.ul_li {
  justify-content: flex-start;
}
.ul_li_center {
  justify-content: center;
}
.ul_li_right {
  justify-content: flex-end;
}
.ul_li_between {
  justify-content: space-between;
}
.ul_li_block {
  margin: 0px;
  padding: 0px;
  display: block;
}
.ul_li_block > li {
  display: block;
  list-style: none;
}
.flex-1 {
  flex: 1;
}
