body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* skin 2 */
.skin-2 .num-in {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  height: 40px;
  width: 110px;
  float: left;
}

.skin-2 .num-in span {
  width: 40%;
  display: block;
  height: 40px;
  float: left;
  position: relative;
}

.skin-2 .num-in span:before,
.skin-2 .num-in span:after {
  content: "";
  position: absolute;
  background-color: #667780;
  height: 2px;
  width: 10px;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -5px;
}

.skin-2 .num-in span.plus:after {
  transform: rotate(90deg);
}

.skin-2 .num-in input {
  float: left;
  background-color: transparent;
  /* width: 20%; */
  width: 10%;
  height: 40px;
  border: none;
  text-align: center;
}
